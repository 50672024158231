<template>
  <div class="game-item" :class="{ maintain: item.maintain }" @click="onClickGame(false)">
    <div class="item game-item__wrapper">
      <CommonBaseImg
        class="game-item__wrapper--image"
        :src="item.img"
        :alt="item.name"
        :border-radius="`${radius ? radius : '16'}`"
        lazy
      />
    </div>
    <div v-if="jackpotNumber && !hiddenJackPot" class="game-item__wrapper--jackpot">
      <div class="jackpot">
        <AnimateCountUp
          v-if="jackpotNumber !== 0"
          :key="jackpotNumber"
          :number="jackpotNumber"
          :show-coin="showCoin"
          :class="[osType]"
        />
      </div>
    </div>
    <div v-if="isPlayNow">
      <div class="game-item__wrapper--popup" :class="{ 'game-item__wrapper--custom_popup': jackpotNumber && !hiddenJackPot }">
        <div class="inner">
          <button>{{ textPlay }}</button>
          <div v-if="isTryToPlay && item.is_demo" class="try-play" @click="onClickGame(true)">Chơi thử</div>
        </div>
      </div>
      <div v-if="showName" class="game-item__wrapper--game-name">{{ item.name }}</div>
    </div>
    <CommonBaseImg v-if="item.maintain" class="game-item__tags" :src="item.tags?.img" />
    <CommonBaseImg
      v-else-if="getThumbImage(item)"
      class="game-item__tags"
      :class="[item?.tags?.name?.toLowerCase()]"
      :src="getThumbImage(item)"
      :alt="`tags ${item.tags?.name}`"
    />
  </div>
</template>

<script setup lang="ts">
import debounce from 'lodash/debounce'
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/store/app'
import useNavigationGame from '~/composables/useNavigationGame'
import AnimateCountUp from '~/components/common/animate-count-up.vue'
import CommonBaseImg from '~/components/common/base-img.vue'
import { IGameItem } from '~/types/common'
const { $pinia, $device } = useNuxtApp()
const store = useAppStore($pinia)
const { jackpot } = storeToRefs(store)
const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()
const osType = ref()
const props = withDefaults(
  defineProps<{
    item: IGameItem
    showCoin?: boolean
    showName?: boolean
    isPlayNow?: boolean
    autoWidth?: boolean
    autoHeight?: boolean
    hiddenJackPot?: boolean
    isTryToPlay?: boolean
    radius?: string
    textPlay?: string
  }>(),
  {
    showCoin: false,
    showName: false,
    isPlayNow: true,
    hiddenJackPot: false,
    autoWidth: false,
    autoHeight: false,
    isTryToPlay: true,
    radius: '4',
    textPlay: 'Chơi Game'
  }
)

const jackpotNumber = computed<number>(() => {
  const keyJackpot: string = props.item.partner_game_id
  const value: Record<string, number> = jackpot.value
  return value[keyJackpot] ?? 0
})

const onClickGame = debounce((isDemo: boolean) => {
  navigationCheckLoggedInAndOpenGame(props.item, undefined, isDemo)
}, 50)
const getThumbImage = (item: IGameItem) => {
  if (item.tags !== null) {
    return item.tags?.img
  }
  return ''
}
onMounted(() => {
  osType.value = $device.isDesktop ? 'desktop' : $device.isIos ? 'ios' : 'android'
})
</script>

<style lang="scss" scoped src="public/assets/scss/components/common/game-item.scss"></style>
